<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">

        <div class="flex h-16 shrink-0 items-center">
            <NomadRxLogo/>
        </div>

        <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in adminHomeNavigation" :key="item.name">
                            <NuxtLink :to="item.href"
                                :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                    :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                    aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400 mb-1">Counterparties</div>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in adminCounterpartiesNavigation" :key="item.name">
                            <NuxtLink :to="item.href"
                                      :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                           :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                           aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400 mb-1">Claim lifecycle</div>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in adminClaimLifecycleNavigation" :key="item.name">
                            <NuxtLink :to="item.href"
                                      :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                           :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                           aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400 mb-1">Analytics</div>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in adminAnalyticsNavigation" :key="item.name">
                            <NuxtLink :to="item.href"
                                      :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                           :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                           aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400 mb-1">Configuration</div>
                    <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in adminConfigurationNavigation" :key="item.name">
                            <NuxtLink :to="item.href"
                                      :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                <component :is="item.icon"
                                           :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                           aria-hidden="true" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>

                <div class="mt-auto">
                    <li class="">
                        <button @click.native="signOutUser"
                            class="group -mx-2 w-full m-0 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6
                                                                                                                                                                                                                                                                                        text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                            <ArrowLeftOnRectangleIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                aria-hidden="true" />
                            Sign Out
                        </button>
                    </li>

                    <li class="">
                        <a href="#"
                            class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                            <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                aria-hidden="true" />
                            Settings
                        </a>
                    </li>
                </div>
            </ul>
        </nav>
    </div>
</template>

<script setup>
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {
    ArrowLeftOnRectangleIcon,
    ArrowPathIcon,
    ChartBarSquareIcon,
    Cog6ToothIcon,
    FolderIcon,
    GlobeAltIcon,
    HomeIcon,
    ServerIcon,
    SignalIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline/esm/index.js'
import { Bars3Icon, ChevronRightIcon, ChevronUpDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid/esm/index.js'
import NomadRxLogo from "~/components/core/NomadRxLogo.vue";

const authStore = useAuthStore();

const {
    adminHomeNavigation,
    adminCounterpartiesNavigation,
    adminClaimLifecycleNavigation,
    adminAnalyticsNavigation,
    adminConfigurationNavigation,
    sidebarOpen
} = useAdminNavigation();
const { signOutUser } = useFirebaseAuth();

const teams = [
    { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
    { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
    { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

const viewAsOtherModalActive = ref(false);

const signOut = async () => {
    try {
        await signOutUser();
    } catch (error) { }
};

const viewAsOtherPartner = () => {
    viewAsOtherModalActive.value = !viewAsOtherModalActive.value;
};
function closeViewAsModal() {
    viewAsOtherModalActive.value = false;
}
</script>